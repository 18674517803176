import React, { useState } from "react";
import "./ResetPassword.scss";
import easings from "constants/easings";
import { motion } from "framer-motion";
import BackgroundGradient from "components/background-gradient";
import withLocation from "hooks/withLocation";
import * as firebase from "firebase/app";
import "firebase/auth";
import LoadingIcon from "components/loading-icon";

const ResetPassword = React.memo(({ labels, search }) => {
    if (!firebase.apps.length) {
        firebase.initializeApp({
            apiKey: "AIzaSyCwd0qkTW2N2cYC0wmN7z7BdefGoy1bVqk",
            authDomain: "superwe-api.firebaseapp.com",
            databaseURL: "https://superwe-api.firebaseio.com",
            projectId: "superwe-api",
            storageBucket: "superwe-api.appspot.com",
            messagingSenderId: "480131237679",
            appId: "1:480131237679:web:ada8cf5886a4c2505d83da",
        });
    }

    const [passwordReset, setPasswordReset] = useState(false);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [loading, setLoading] = useState(false);

    const confirmPasswordReset = () => {
        if (search && search.oobCode && search.mode === "resetPassword") {
            // If password not entered
            if (password === "" || password === undefined)
                alert("Please enter Password");
            // If confirm password not entered
            else if (password2 === "" || password2 === undefined)
                alert("Please enter confirm password");
            // If Not same return False.
            else if (password !== password2) {
                alert("\nPassword did not match: Please try again...");
                return false;
            }
            // If same return True.
            else {
                setLoading(true);
                firebase
                    .auth()
                    .confirmPasswordReset(search.oobCode, password)
                    .then(() => {
                        setPasswordReset(true);
                        setLoading(false);
                        setPassword("");
                    })
                    .catch((error) => {
                        console.log("error", error);
                        setLoading(false);
                        setPassword("");
                    });
            }
        } else {
            alert("missing oobCode or mode");
        }
    };

    const animateArticleItem = {
        initial: {
            y: 20,
            opacity: 0,
        },
        enter: {
            y: 0,
            opacity: 1,
            transition: {
                type: "tween",
                ease: easings.one,
                duration: 0.6,
            },
        },
    };

    return (
        <motion.div variants={animateArticleItem} className="reset-password">
            <div className="input-container">
                <form autoComplete="off">
                    <input
                        autoComplete="off"
                        value={password}
                        type="password"
                        onChange={(event) => setPassword(event.target.value)}
                        placeholder="New password"
                    />
                    <input
                        autoComplete="off"
                        value={password2}
                        type="password"
                        onChange={(event) => setPassword2(event.target.value)}
                        placeholder="New password again.."
                    />
                </form>
            </div>

            {!passwordReset && (
                <button
                    disabled={loading}
                    type="button"
                    onClick={confirmPasswordReset}
                >
                    <BackgroundGradient />
                    <span className={loading ? "loading" : ""}>
                        {labels.resetPasswordButtonLabel}
                    </span>
                    {loading && <LoadingIcon className="loading-icon--small" />}
                </button>
            )}

            {passwordReset && (
                <motion.div
                    className="approved"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{
                        opacity: 1,
                        y: 0,
                    }}
                >
                    <BackgroundGradient />
                    <span>Password reset!</span>
                </motion.div>
            )}
        </motion.div>
    );
});

export default withLocation(ResetPassword);
